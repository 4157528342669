import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
import "../App.scss";

export const GalleryPage = () => {
  const photoNumber = 18;
  const numberArray = Array.from(Array(photoNumber).keys()).map((i) => i + 1);
  return (
    <div id="wrapper">
      <div className="secondary-container">
        <Row>
          <Col md={4}>
            {numberArray
              .filter((i) => i % 3 === 1)
              .map((i) => {
                return (
                  <LazyLoadImage
                    className="my-3 img-fluid"
                    key={i}
                    alt={`photo-${i}.jpg`}
                    placeholderSrc={`/lazy-gallery/photo-${i}-lazy-load.jpg`}
                    src={`/gallery/photo-${i}.jpg`}
                    effect="blur"
                  />
                );
              })}
          </Col>
          <Col md={4}>
            {numberArray
              .filter((i) => i % 3 === 2)
              .map((i) => {
                return (
                  <LazyLoadImage
                    className="my-3 img-fluid"
                    key={i}
                    alt={`photo-${i}.jpg`}
                    placeholderSrc={`/lazy-gallery/photo-${i}-lazy-load.jpg`}
                    src={`/gallery/photo-${i}.jpg`}
                    effect="blur"
                  />
                );
              })}
          </Col>
          <Col md={4}>
            {numberArray
              .filter((i) => i % 3 === 0)
              .map((i) => {
                return (
                  <LazyLoadImage
                    className="my-3 img-fluid"
                    key={i}
                    alt={`photo-${i}.jpg`}
                    placeholderSrc={`/lazy-gallery/photo-${i}-lazy-load.jpg`}
                    src={`/gallery/photo-${i}.jpg`}
                    effect="blur"
                  />
                );
              })}
          </Col>
        </Row>
      </div>
    </div>
  );
};
