import { PAGE_SIZE } from "constants/constant";
import { ContentType, Tag } from "constants/enum";
import * as contentful from "contentful";
import { BirdRecord, Blog } from "types/types";

const client = contentful.createClient({
  space: process.env.REACT_APP_CONTENTFUL_SPACE,
  accessToken: process.env.REACT_APP_CONTENTFUL_ACCESS_TOKEN,
  environment: process.env.REACT_APP_CONTENTFUL_ENV,
});

const OrderCreatedDESC = "-sys.createdAt";
const IndexASC = "fields.index";

class ContentService {
  private parse(item: contentful.Entry<any>) {
    const {
      metadata,
      fields,
      sys: { createdAt },
    } = item;

    const tags = metadata.tags.map((tag) => tag.sys.id as Tag);
    const image = (fields as any).image.fields.file.url;

    return {
      ...(fields as any),
      image,
      createdAt: new Date(createdAt),
      tags,
    };
  }

  private parseBirdRecord(item: contentful.Entry<any>): BirdRecord {
    const {
      fields,
      sys: { createdAt },
    } = item;

    const images = fields["images"].map((image) => image.fields.file.url);
    return { ...fields, images, createdAt: new Date(createdAt) };
  }

  async get(): Promise<Blog[]> {
    const { items } = await client.getEntries({
      content_type: ContentType.BLOG,
      order: OrderCreatedDESC,
    });
    return items.map((item) => this.parse(item));
  }

  async getOneBySlug(slug: string): Promise<Blog> {
    const { items } = await client.getEntries({
      content_type: ContentType.BLOG,
      "fields.slug[in]": slug,
    });
    return this.parse(items[0]);
  }

  async listBirdRecords(
    skip = 0,
    limit = PAGE_SIZE,
  ): Promise<[BirdRecord[], number]> {
    const { items, total } = await client.getEntries({
      content_type: ContentType.BIRD_RECORD,
      order: IndexASC,
      skip,
      limit,
    });
    return [items.map((item) => this.parseBirdRecord(item)), total];
  }
}

export const contentService = new ContentService();
