import "components/NavBar.scss";
import { formatDate } from "utils/date";
import React from "react";

interface Props {
  date: Date;
}

export const DateLabel = (props: Props) => {
  const { date } = props;

  return <small className="date">{formatDate(date)}</small>;
};
