export const MetaData = {
  name: "Krit Kruaykitanon",
  websiteUrl: "https://kritkruaykitanon.com",
  githubUrl: "https://github.com/oozygrub",
  facebookUrl: "https://www.facebook.com/krit.kruaykitanon",
  linkedInUrl: "https://www.linkedin.com/in/krit-kruaykitanon-04a298198",
  instagramUrl: "https://www.instagram.com/oufeow",
  youtubeUrl: "https://www.youtube.com/channel/UCd3468ZLvhP1y39hxOkl0Gg",
  eBirdUrl: "https://ebird.org/profile/MjAyMzM1NA/TH",
  iNaturalistUrl: "https://www.inaturalist.org/people/5535430",
  facebookAppId: "751141258880608",
};

export const SocialLinks = [
  {
    name: "Facebook",
    url: MetaData.facebookUrl,
  },
  {
    name: "Instagram",
    url: MetaData.instagramUrl,
  },
  {
    name: "Github",
    url: MetaData.githubUrl,
  },
  {
    name: "LinkedIn",
    url: MetaData.linkedInUrl,
  },
  {
    name: "eBird",
    url: MetaData.eBirdUrl,
  },
  {
    name: "iNaturalist",
    url: MetaData.iNaturalistUrl,
  },
];
