import { NavbarOption } from "constants/enum";
import { useState } from "react";
import { Navbar as BSNavbar, Nav } from "react-bootstrap";
import { Link } from "react-router-dom";
import "./NavBar.scss";
import { Routes } from "constants/routes";

enum NavBarColor {
  BLACK = "#000000",
  GREY = "#616161",
  WHITE = "#FFFFFF",
}

enum NavBarVariant {
  DARK = "dark",
  LIGHT = "light",
}

const NavbarTheme = {
  [NavbarOption.BLACK]: {
    color: NavBarColor.BLACK,
    textColor: NavBarColor.WHITE,
    src: "/logo/logo-192.png",
    variant: NavBarVariant.DARK,
  },
  [NavbarOption.WHITE]: {
    color: NavBarColor.WHITE,
    textColor: NavBarColor.GREY,
    src: "/logo/logo-192-black.png",
    variant: NavBarVariant.LIGHT,
  },
};

type NavBar = {
  color: NavBarColor;
  textColor: NavBarColor;
  src: string;
  variant?: NavBarVariant;
};

interface Props {
  option: NavbarOption;
}
export const Navbar = (props: Props) => {
  const { option = NavbarOption.WHITE } = props;

  const [theme] = useState<NavBar>(NavbarTheme[option]);
  const [expanded, setExpanded] = useState(false);

  return (
    <BSNavbar
      style={{ backgroundColor: theme.color }}
      className="navbar"
      expand="lg"
      variant={theme.variant}
      expanded={expanded}
    >
      <BSNavbar.Brand>
        <Link className="nav-link" to="/">
          <img
            alt=""
            src={theme.src}
            width="30"
            height="30"
            className="d-inline-block align-top"
          />
        </Link>
      </BSNavbar.Brand>
      <BSNavbar.Toggle
        aria-controls="basic-navbar-nav"
        onClick={() => setExpanded((expanded) => !expanded)}
      />
      <BSNavbar.Collapse id="basic-navbar-nav">
        <Nav style={{ color: theme.textColor }} className="ml-5 mb-2">
          {Routes.filter((route) => route.navbar).map((page) => (
            <Link
              className="nav-link mx-3 my-2"
              to={page.path}
              key={page.title}
              onClick={() => setExpanded(false)}
            >
              {page.title}
            </Link>
          ))}
          {/* <a
            className="nav-link mx-3 my-2"
            href="https://webring.wonderful.software#kritkruaykitanon.com"
          >
            <img
              alt="วงแหวนเว็บ"
              width="24"
              height="24"
              src="https://webring.wonderful.software/webring.black.svg"
            />
          </a> */}
        </Nav>
      </BSNavbar.Collapse>
    </BSNavbar>
  );
};
