import { init, track } from "@amplitude/analytics-node";
import { EventType } from "constants/constant";
import { v4 as uuidv4 } from "uuid";

init(process.env.REACT_APP_AMPLITUDE_API_KEY);

export class Analytics {
  static track(event_type: EventType, event_properties: any) {
    track({
      event_type,
      event_properties,
      user_id: uuidv4(),
    });
  }
}
