import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import { BLOCKS, INLINES } from "@contentful/rich-text-types";
import "App.scss";
import { DateLabel } from "components/DateLabel";
import { SEO } from "components/SEO";
import { TagLabel } from "components/TagLabel";
import React, { useEffect, useState } from "react";
import { Col, Image, Row } from "react-bootstrap";
import { useParams } from "react-router-dom";
import { contentService } from "services/content.service";
import { Blog } from "types/types";

export const BlogDetailPage = () => {
  const { slug } = useParams<{ slug: string }>();
  const [blog, setBlog] = useState<Blog>(null);

  const fetchBlog = async () => {
    const blog = await contentService.getOneBySlug(slug);
    setBlog(blog);
  };

  useEffect(() => {
    if (slug) fetchBlog();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [slug]);

  if (!blog) return null;

  const { tags, title, body, createdAt, image, description } = blog;

  const options = {
    renderNode: {
      [BLOCKS.PARAGRAPH]: (_, child) => (
        <p style={{ lineHeight: "1.75rem", marginTop: "1.5rem" }}>{child}</p>
      ),
      [BLOCKS.HEADING_3]: (_, child) => (
        <h3 style={{ lineHeight: "1.75rem", marginTop: "1.5rem" }}>{child}</h3>
      ),
      [BLOCKS.EMBEDDED_ASSET]: (node) => {
        const { url } = node.data.target.fields.file;
        return <Image className="mb-4" src={url} width="100%" />;
      },
      [INLINES.HYPERLINK]: (node, child) => {
        const url: string = node.data.uri;
        if (url.includes("youtube")) {
          return (
            <div className="container-iframe">
              <iframe
                className="responsive-iframe"
                src={url}
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              ></iframe>
            </div>
          );
        }
        return <a href={url}>{child}</a>;
      },
    },
  };

  return (
    <>
      <SEO title={title} description={description} image={image} slug={slug} />
      <Image src={image} className="cover-img" />
      <div
        className="secondary-container my-5"
        style={{
          display: "flex",
          flexDirection: "column",
          gap: "1rem",
        }}
      >
        <h1>{title}</h1>
        <p>{description}</p>
        <Row>
          <Col className="d-flex gap-1">
            {tags.map((tag) => (
              <TagLabel key={tag} tag={tag} />
            ))}
          </Col>
          <Col className="d-flex flex-row-reverse">
            <DateLabel date={createdAt} />
          </Col>
        </Row>
        <hr />
        <Row>
          <Col>{documentToReactComponents(body, options)}</Col>
        </Row>
        <hr />
      </div>
    </>
  );
};
