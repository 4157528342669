import { useEffect, useRef } from "react";

export const Buymeacoffee = () => {
  const scriptRef = useRef(null);
  const divRef = useRef(null);

  const id = "bmc-wbtn";

  // const
  useEffect(() => {
    const script = document.createElement("script");
    const div = document.createElement("div");
    script.setAttribute(
      "src",
      "https://cdnjs.buymeacoffee.com/1.0.0/widget.prod.min.js",
    );
    script.setAttribute("data-name", "BMC-Widget");
    script.setAttribute("data-cfasync", "false");
    script.setAttribute("data-id", "oudoonok");
    script.setAttribute("data-description", "Support me on Buy me a coffee!");
    script.setAttribute("data-message", "Support me on Buy me a coffee!");
    script.setAttribute("data-color", "#FFDD00");
    script.setAttribute("data-position", "Right");
    script.setAttribute("data-x_margin", "18");
    script.setAttribute("data-y_margin", "18");

    script.onload = function () {
      const evt = new Event("DOMContentLoaded", {
        bubbles: false,
        cancelable: false,
      });
      window.dispatchEvent(evt);
    };

    div.appendChild(script);
    divRef.current.appendChild(div);
    scriptRef.current = script;

    return () => {
      document.getElementById(id).remove();
    };
  }, []);

  return <div ref={divRef} />;
};
