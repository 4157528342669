import { MetaData, SocialLinks } from "constants/metadata";

export const Footer = () => {
  return (
    <div
      className="footer"
      style={{
        display: "flex",
        flexWrap: "wrap",
        justifyContent: "center",
        flexDirection: "row",
        gap: "1rem",
        margin: "5rem 0.5rem",
      }}
    >
      <p>
        {MetaData.name} © {new Date().getFullYear()}
      </p>
      {SocialLinks.map((socialLink) => (
        <a
          key={socialLink.name}
          style={{
            color: "inherit",
            textDecoration: "none",
            margin: "0 0.5rem",
          }}
          href={socialLink.url}
          target="_blank"
          rel="noreferrer"
        >
          {socialLink.name}
        </a>
      ))}
    </div>
  );
};
