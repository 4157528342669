import { useEffect } from "react";
import { Spinner } from "react-bootstrap";

export const RedirectPage = ({ to }: { to: string }) => {
  useEffect(() => {
    window.location.replace(to);
  }, [to]);

  //   style redirecting ui
  return <div>Redirecting</div>;
};
