import { AboutPage } from "views/AboutPage";
import { BirdPage } from "views/BirdPage";
import { BlogDetailPage } from "views/BlogDetailPage";
import { BlogListPage } from "views/BlogListPage";
import { GalleryPage } from "views/GalleryPage";
import { HomePage } from "views/HomePage";
import { Path } from "./constant";

export const Routes = [
  {
    path: Path.HOME,
    component: HomePage,
    title: "Home",
  },
  // {
  //   path: Path.BLOG,
  //   component: BlogListPage,
  //   title: "Blog",
  //   navbar: true,
  // },
  {
    path: Path.GALLERY,
    component: GalleryPage,
    title: "Gallery",
    navbar: true,
  },
  {
    path: Path.BIRDS,
    component: BirdPage,
    title: "Birds",
    navbar: true,
  },
  // {
  //   path: Path.ABOUT,
  //   component: AboutPage,
  //   title: "About",
  //   navbar: true,
  // },
  {
    path: Path.BLOG + "/:slug",
    component: BlogDetailPage,
    title: "Blog",
  },
];
