import "App.scss";
import { EventType, Path } from "constants/constant";
import { useLocation } from "react-router-dom";
import { Analytics } from "utils/analytics";

const links = [
  {
    title: "Blog",
    href: "https://medium.com/@kritkruaykitanon",
  },
  {
    title: "Gallery",
    href: Path.GALLERY,
  },
  {
    title: "Birds",
    href: Path.BIRDS,
  },
];

export const HomePage = () => {
  const location = useLocation();

  return (
    <div id="wrapper" className="center">
      <div className="circle mb-3">
        <img src="myself.jpeg" className="App-logo" alt="logo" />
      </div>
      <h2>Krit Kruaykitanon</h2>
      {/* <Container>
        <Row className="justify-content-center py-3">
          <Col md="8">
 <p>{typography.name.description}</p> 
 <p>{typography.visitedCountries}</p> 
          </Col>
        </Row>
      </Container> 
      */}
      <div className="home-links">
        {links.map((link) => (
          <a
            onClick={() => {
              Analytics.track(EventType.LINK_CLICKED, {
                value: link.href,
                page: location.pathname,
                search: location.search,
              });
            }}
            href={link.href}
            key={link.href}
          >
            {link.title}
          </a>
        ))}
      </div>
      {/* <Link to="/blog">
        <Button variant="primary" size="lg" className="home-btn">
          Enter
        </Button>
      </Link> */}
    </div>
  );
};
