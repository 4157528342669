export enum NavbarOption {
  WHITE = "white",
  BLACK = "black",
}

export enum Tag {
  LIFE = "life",
}

export const TagColor = {
  [Tag.LIFE]: "#556b2f",
};

export enum ContentType {
  BLOG = "blog",
  BIRD_RECORD = "birdRecord",
}
