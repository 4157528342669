import { MetaData } from "constants/metadata";
import { compact, flow, join } from "lodash/fp";
import { Helmet } from "react-helmet";

interface Props {
  title?: string;
  description?: string;
  image?: string;
  slug?: string;
}

export const SEO = ({
  title,
  description = "A brief history of krit",
  image = "/logo/logo-600-bg.png",
  slug,
}: Props) => {
  const seoTitle = flow(compact, join(" | "))([title, "kritkruaykitanon.com"]);

  const meta = [
    {
      property: "charset",
      content: "utf-8",
    },
    {
      property: "keywords",
      content: "Krit Kruaykitanon, kritkruaykitanon.com, OozyGrub",
    },
    {
      property: "author",
      content: "Krit Kruaykitanon",
    },
    {
      property: "theme-color",
      content: "#556b2f",
    },
    {
      name: "viewport",
      content: "width=device-width, initial-scale=1",
    },
    {
      property: "description",
      content: description,
    },
    {
      property: "og:description",
      content: description,
    },
    {
      property: "og:url",
      content: slug
        ? `${MetaData.websiteUrl}/blog/${slug}`
        : MetaData.websiteUrl,
    },
    {
      property: "image",
      content: image,
    },
    {
      property: "og:image",
      content: image,
    },
    {
      property: "og:image:secure_url",
      content: image,
    },
    {
      property: "og:title",
      content: seoTitle,
    },
    {
      property: "fb:app_id",
      content: MetaData.facebookAppId,
    },
    {
      property: "og:type",
      content: "article",
    },
    {
      property: "og:locale",
      content: "th_TH",
    },
    {
      property: "og:locale:alternate",
      content: "en_US",
    },
  ];

  return <Helmet meta={meta} title={seoTitle} />;
};
